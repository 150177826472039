import React, { Fragment, useEffect } from "react";
import axios from "axios";
import { Card, Dropdown, Form, Header, Button, Image, Dimmer, Loader, Segment, Container, Pagination } from "semantic-ui-react";
import momentTimeZone from 'moment-timezone';
import moment from "moment";
import _ from "lodash";
import Iframe from 'react-iframe'
import { withRouter } from 'react-router-dom';
import qs from "query-string";
import ReactStars from "react-rating-stars-component";

const getSessionTypeFromCategory = (category) => category.split("(")[0].trim();

const getAgeGroupFromCategory = (category) => {
  const matches = category?.match(/\(([^)]+)\)/);
  return matches ? matches.pop() : "";
};

const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const TIMES_OF_DAY = [
  "Morning",
  "Afternoon",
  "Evening",
];

const preferredTimezones = [
  'US/Hawaii',
  'US/Alaska',
  'US/Pacific',
  'US/Mountain',
  'US/Arizona',
  'US/Central',
  'US/Eastern'
];

const ITEMS_PER_PAGE = 25;

const App = ({ location, history }) => {
  const [loading, setLoading] = React.useState(false);
  const [ageGroups, setAgeGroups] = React.useState([]);
  const [sessionTypes, setSessionTypes] = React.useState([]);
  const [spots, setSpots] = React.useState(1);
  const [timezone, setTimezone] = React.useState(momentTimeZone.tz.guess());
  const [daysOfWeek, setDaysOfWeek] = React.useState([]);
  const [timesOfDay, setTimesOfDay] = React.useState([]);
  const [performers, setPerformers] = React.useState([]);
  const [ageGroupOptions, setAgeGroupOptions] = React.useState([]);
  const [sessionTypeOptions, setSessionTypeOptions] = React.useState([]);
  const [performerOptions, setPerformerOptions] = React.useState([]);
  const [appointments, setAppointments] = React.useState([]);
  const [chosenAppointment, setChosenAppointment] = React.useState("");
  const [chosenAppointmentLoading, setChosenAppointmentLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [reviewsByPerformer, setReviewsByPerformer] = React.useState({});
  const [reviewModalPerformer, setReviewModalPerformer] = React.useState(null);
  const [, setError] = React.useState(null);

  const timezoneOptions = React.useMemo(() => {
    const allTimezoneOptions = momentTimeZone.tz.names().map(option => ({
      key: option,
      text: `(GMT${moment().tz(option).format("Z")}) ${option}`,
      value: option,
    }));

    const currentTimezoneOption = allTimezoneOptions.find(tz => tz.key === timezone);
    const preferredTimezoneOptions = allTimezoneOptions.filter(tz => preferredTimezones.includes(tz.key) && tz.key !== currentTimezoneOption.key);
    const otherTimezoneOptions = allTimezoneOptions.filter(tz => !preferredTimezones.includes(tz.key) && tz.key !== currentTimezoneOption.key);

    return [
      currentTimezoneOption,
      ...preferredTimezoneOptions,
      ...otherTimezoneOptions,
    ];
  }, [timezone]);
  
  const getPerformerReviews = React.useCallback(() => {
    axios.get(`${process.env.REACT_APP_REVIEWRAIL_BASE_URL}/reviews/${process.env.REACT_APP_REVIEWRAIL_CLIENT_ID}`).then(response => {
      setReviewsByPerformer(_.groupBy(response.data.reviews, 'calendar_name'));
    });
  }, []);

  const getPerformerOptions = React.useCallback((queryPerformers) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/calendars`).then(response => {
      setPerformerOptions(response.data.map(option => ({
        key: option.id.toString(),
        text: option.name,
        value: option.id.toString(),
      })));
      setPerformers(queryPerformers.filter(performer => response.data.map(option => option.id.toString()).includes(performer)));
    });
  }, []);

  const getAppointmentOptions = React.useCallback((queryAgeGroups, querySessionTypes) => {
    setLoading(true);

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/classes`)
      .then(response => {
        const sessionTypeOptions = _.uniq(
          response.data.filter(option => option.category)
            .map(option => getSessionTypeFromCategory(option.category))
            .filter(option => option)
        ).sort();
        const ageGroupOptions = _.uniq(
          response.data.filter(option => option.category)
            .map(option => getAgeGroupFromCategory(option.category))
            .filter(option => option)
        ).sort();

        setAppointments(response.data);
        setSessionTypeOptions(sessionTypeOptions.map(option => ({
          key: option,
          text: option,
          value: option,
        })));
        setAgeGroupOptions(ageGroupOptions.map(option => ({
          key: option,
          text: option,
          value: option,
        })));
        setAgeGroups(queryAgeGroups.filter(ageGroup => ageGroupOptions.includes(ageGroup)));
        setSessionTypes(querySessionTypes.filter(sessionType => sessionTypeOptions.includes(sessionType)));
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (!getPerformerReviews || !getPerformerOptions || !getAppointmentOptions || !history) {
      return;
    }

    const {
      ageGroup,
      sessionType,
      spots,
      timezone,
      dayOfWeek,
      timeOfDay,
      performer,
    } = qs.parse(location.search || "");

    const queryAgeGroups = ageGroup ? (Array.isArray(ageGroup) ? _.uniq(ageGroup.filter(x => x)) : [ageGroup]) : [];

    const querySessionTypes = sessionType ? (Array.isArray(sessionType) ? _.uniq(sessionType.filter(x => x)) : [sessionType]) : [];

    parseInt(spots) && parseInt(spots) > 0 && setSpots(parseInt(spots));

    timezone && momentTimeZone.tz.names().includes(timezone) && setTimezone(timezone);

    dayOfWeek && setDaysOfWeek(Array.isArray(dayOfWeek) ? _.uniq(dayOfWeek.filter(x => DAYS_OF_WEEK.includes(x))) : (
      DAYS_OF_WEEK.includes(dayOfWeek) ? [dayOfWeek] : []
    ));

    timeOfDay && setTimesOfDay(Array.isArray(timeOfDay) ? _.uniq(timeOfDay.filter(x => TIMES_OF_DAY.includes(x))) : (
      TIMES_OF_DAY.includes(timeOfDay) ? [timeOfDay] : []
    ));

    const queryPerformers = performer ? (Array.isArray(performer) ? _.uniq(performer.filter(x => x).map(x => x.toString())) : [performer.toString()]) : [];

    history.push({ search: '' });

    getPerformerReviews();
    getPerformerOptions(queryPerformers);
    getAppointmentOptions(queryAgeGroups, querySessionTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getPerformerReviews,
    getPerformerOptions,
    getAppointmentOptions,
    history,
  ]);

  const filteredAppointments = React.useMemo(() => appointments.filter(appointment => {
    const appointmentTime = moment(appointment.time).tz(timezone);

    return (
      (!ageGroups.length || ageGroups.includes(getAgeGroupFromCategory(appointment.category)))
      && (!sessionTypes.length || sessionTypes.includes(getSessionTypeFromCategory(appointment.category)))
      && (!performers.length || performers.includes(appointment.calendarID.toString()))
      && spots <= appointment.slotsAvailable
      && (!daysOfWeek.length || daysOfWeek.includes(appointmentTime.format("dddd")))
      && (
        !timesOfDay.length
        || (timesOfDay.includes("Morning") && appointmentTime.hour() < 12)
        || (timesOfDay.includes("Afternoon") && appointmentTime.hour() >= 12 && appointmentTime.hour() < 18)
        || (timesOfDay.includes("Evening") && appointmentTime.hour() > 18)
      )
    );
  }), [
    ageGroups,
    sessionTypes,
    performers,
    spots,
    daysOfWeek,
    timesOfDay,
    timezone,
    appointments,
  ]);

  const pageCount = React.useMemo(() => Math.ceil(filteredAppointments.length / ITEMS_PER_PAGE), [filteredAppointments]);

  useEffect(() => {
    if (currentPage + 1 > pageCount) {
      setCurrentPage(0);
    }
  }, [pageCount, currentPage]);

  const appointmentPages = React.useMemo(() => {
    const pages = _.chunk(filteredAppointments, ITEMS_PER_PAGE).map(page => _.groupBy(page.sort((a, b) => moment(a.time).diff(moment(b.time))), function(appointment) {
      return moment(appointment.time).format("dddd, MMMM D, YYYY");
    }));

    return pages.map(page => {
      return Object.keys(page).map(day => {
        const filteredAppointmentsForDay = page[day];

        if (!filteredAppointmentsForDay.length) {
          return null;
        }
  
        return (
          <Fragment key={day}>
            <Header content={day} className="deferToInheritedFontFamily"/>
            {filteredAppointmentsForDay.map(appointment => {
              const duration = moment.utc().startOf('day').add({ minutes: appointment.duration });
              const durationHours = duration.hours();
              const durationMinutes = duration.minutes();
  
              const ageGroup = getAgeGroupFromCategory(appointment.category);
  
              const sessionLength = `${durationHours > 0 ? `${durationHours} Hour${durationHours > 1 ? "s" : ""} ` : ""}${durationMinutes > 0 ? `${durationMinutes} Minute${durationMinutes > 1 ? "s" : ""} ` : ""}`;
              const sessionPrice = appointment.price && parseFloat(appointment.price);
  
              return (
                <Card.Group key={appointment.id} style={{ margin: "0 1px" }}>
                  <Card fluid>
                    <Card.Content>
                      <Image size="small" floated="right" src={appointment.image} />
                      <Card.Header className="deferToInheritedFontFamily">{`${appointment.name}${ageGroup ? ` (${ageGroup})` : ""}`}</Card.Header>
                      <Card.Meta>
                        <span>{moment(appointment.time).tz(timezone).format("h:mm A,  dddd, MMMM D, YYYY")}</span><br/>
                        <span>{`${sessionLength}${sessionPrice ? `@ $${sessionPrice.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").replace('.00', '')}` : ''}`}</span>
                      </Card.Meta>
                      <Card.Description>
                        {appointment.description}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="deferToInheritedFontFamily"
                        color="blue"
                        onClick={() => {
                          setChosenAppointment(`${appointment.schedulingUrl}?datetime=${moment(appointment.time).tz(appointment.calendarTimezone).format("YYYY-MM-DDTHH:mm:ss")}&appointmentType=${appointment.appointmentTypeID}&quantity=${spots}&timezone=${timezone}`);
                          setChosenAppointmentLoading(true);
                          Array.prototype.slice.call(document.getElementsByTagName("div")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                          Array.prototype.slice.call(document.getElementsByTagName("body")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                          Array.prototype.slice.call(document.getElementsByTagName("html")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                          window.scroll({ top: 0, behavior: 'smooth' });
                        }}
                      >
                        Sign Up
                      </Button>
                      <span style={{ marginLeft: "0.5em" }}>{appointment.slotsAvailable} Spot{appointment.slotsAvailable > 1 ? "s" : ""} Left</span>
                      <div style={{ flexGrow: "1" }} />
                      {appointment.reviewData && (
                        <div
                          className="performer-review"
                          style={{ float: "right", display: "flex", alignItems: "center", gap: "8px" }}
                          onClick={() => setReviewModalPerformer(appointment.calendar)}
                        >
                          <div style={{ paddingBottom: "3px" }}>
                            <ReactStars
                              count={5}
                              value={appointment.reviewData.averageRating}
                              size={24}
                              edit={false}
                              isHalf
                            />
                          </div>
                          {`(${appointment.reviewData.totalReviews})`}
                        </div>
                      )}
                    </Card.Content>
                  </Card>
                </Card.Group>
              );
            })}
          </Fragment>
        )
      });
    });
  }, [
    filteredAppointments,
    spots,
    timezone,
  ]);

  if (reviewModalPerformer) {
    return (
      <Container>
        <Button
          icon='arrow left'
          labelPosition='left'
          fluid
          style={{ maxWidth: "900px", margin: "0 auto 16px" }}
          className="deferToInheritedFontFamily"
          content="Return to Availability"
          onClick={() => setReviewModalPerformer(null)}
        />
        <div style={{ maxWidth: "900px", margin: "0 auto" }}>
          {reviewsByPerformer[reviewModalPerformer] ? (
            reviewsByPerformer[reviewModalPerformer].map(review => (
              <Card key={review.hashd} fluid style={{ padding: "10px 16px 16px" }} className="review-card">
                <ReactStars
                  count={5}
                  value={review.rating}
                  size={24}
                  edit={false}
                  isHalf
                />
                <div style={{ marginTop: "8px"}}>
                  {review.feedback}
                </div>
              </Card>
            ))
          ) : null}
        </div>
      </Container>
    );
  }

  if (chosenAppointment) {
    return (
      <Container>
        <Dimmer active={chosenAppointmentLoading} inverted>
          <Loader />
        </Dimmer>
        <Button
          icon='arrow left'
          labelPosition='left'
          fluid
          style={{ maxWidth: "900px", margin: "0 auto -22px" }}
          className="deferToInheritedFontFamily"
          content="Return to Availability"
          onClick={() => setChosenAppointment(null)}
        />
        <Iframe
          url={chosenAppointment}
          width="100%"
          height="1000px"
          frameBorder="0"
          onLoad={() => setChosenAppointmentLoading(false)}
        />
      </Container>
    );
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Age Group</label>
            <Dropdown
              placeholder="All Ages"
              selection
              multiple
              options={ageGroupOptions}
              onChange={(event, data) => setAgeGroups(data.value)}
              value={ageGroups}
            />
          </Form.Field>
          <Form.Field>
            <label>Activity Type</label>
            <Dropdown
              placeholder="All Activity Types"
              selection
              multiple
              search
              options={sessionTypeOptions}
              onChange={(event, data) => setSessionTypes(data.value)}
              value={sessionTypes}
            />
          </Form.Field>
          <Form.Field>
            <label>Performer</label>
            <Dropdown
              placeholder="All Performers"
              selection
              multiple
              search
              options={performerOptions}
              onChange={(event, data) => setPerformers(data.value)}
              value={performers}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field className="deferToInheritedFontFamily">
            <label># of Kids Participating</label>
            <input
              type='number'
              value={spots}
              onChange={(event) => setSpots(event.target.valueAsNumber)}
              onBlur={() => setSpots(spots || 1)}
              min={1}
              inputmode={isTouch ? "numeric" : undefined}
            />
          </Form.Field>
          <Form.Field>
            <label>Day of Week</label>
            <Dropdown
              placeholder="All Days"
              selection
              multiple
              options={DAYS_OF_WEEK.map(option => ({
                key: option,
                text: option,
                value: option,
              }))}
              onChange={(event, data) => setDaysOfWeek(data.value)}
              value={daysOfWeek}
            />
          </Form.Field>
          <Form.Field>
            <label>Time of Day</label>
            <Dropdown
              placeholder="All Times"
              selection
              multiple
              options={TIMES_OF_DAY.map(option => ({
                key: option,
                text: option,
                value: option,
              }))}
              onChange={(event, data) => setTimesOfDay(data.value)}
              value={timesOfDay}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Time Zone</label>
            <Dropdown
              selection
              options={timezoneOptions}
              onChange={(event, data) => setTimezone(data.value)}
              value={timezone}
            />
          </Form.Field>
        </Form.Group>
        <Segment basic textAlign="center">
          <Button
            icon='delete'
            labelPosition='left'
            className="deferToInheritedFontFamily"
            content="Reset Filters"
            disabled={
              !ageGroups.length
              && !sessionTypes.length
              && spots === 1
              && !daysOfWeek.length
              && !timesOfDay.length
              && !performers.length
            }
            onClick={() => {
              setAgeGroups([]);
              setSessionTypes([]);
              setSpots(1);
              setDaysOfWeek([]);
              setTimesOfDay([]);
              setPerformers([]);
            }}
          />
        </Segment>
      </Form>
      {!loading && !!appointmentPages.length && currentPage < appointmentPages.length && (
        <>
          {appointmentPages[currentPage]}
          <Segment basic textAlign="center">
            <Pagination
              activePage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={(e, { activePage }) => {
                setCurrentPage(activePage - 1);
                Array.prototype.slice.call(document.getElementsByTagName("div")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                Array.prototype.slice.call(document.getElementsByTagName("body")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                Array.prototype.slice.call(document.getElementsByTagName("html")).forEach(element => element.scroll({ top: 0, behavior: 'smooth' }));
                window.scroll({ top: 0, behavior: 'smooth' });
              }}
            />
          </Segment>
        </>
      )}
      {!loading && (!appointments.length || appointments.every(appointmentDay => !appointmentDay)) && (
        <Segment basic textAlign="center" content="No available appointments match your selections." />
      )}
    </>
  );
};

export default withRouter(App);
